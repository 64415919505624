import React, { useEffect, useMemo } from 'react';
import { Tabs, TabsProps } from 'antd';
import { FormattedMessage } from 'react-intl';
import ManagerMainLayout from '../../../../components/layout/ManagerMainLayout';
import SettingsUsers from '../SettingsUsers';
import SettingsGroups from '../SettingsGroups';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardList } from '../../../dashboard/dashboardSlice';
import { hasAccess } from '../../../../utils/rights';
import { selectCurrentUserGrants } from '../../../user/userSlice';
import { Permissions } from '../../types/user';
import SettingsData from '../SettingsData';

const USERS_PERMISSIONS = [Permissions.USER_EDIT, Permissions.USER_READ];
const GROUPS_PERMISSIONS = [Permissions.GROUP_EDIT, Permissions.GROUP_READ];
const DATA_PERMISSIONS = [Permissions.DATA_LOAD];

const Settings = () => {
  const userGrants = useSelector(selectCurrentUserGrants);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDashboardList());
  }, []);

  const tabs = useMemo(() => {
    const hasUsersTabAccess = hasAccess(USERS_PERMISSIONS, userGrants);
    const hasGroupsTabAccess = hasAccess(GROUPS_PERMISSIONS, userGrants);
    const hasDataTabAccess = hasAccess(DATA_PERMISSIONS, userGrants);

    const result: TabsProps['items'] = [];

    if (hasUsersTabAccess) {
      result.push({
        key: 'users',
        label: (
          <FormattedMessage
            id="settings.Accounts"
            defaultMessage="Учетные записи"
          />
        ),
        children: <SettingsUsers />,
      });
    }

    if (hasGroupsTabAccess) {
      result.push({
        key: 'groups',
        label: (
          <FormattedMessage id="settings.Groups" defaultMessage="Группы" />
        ),
        children: <SettingsGroups />,
      });
    }

    if (hasDataTabAccess) {
      result.push({
        key: 'data',
        label: (
          <FormattedMessage
            id="settings.Data"
            defaultMessage="Работа с данными"
          />
        ),
        children: <SettingsData />,
      });
    }

    return result;
  }, [userGrants]);

  return (
    <ManagerMainLayout>
      <Tabs defaultActiveKey="users" items={tabs} />
    </ManagerMainLayout>
  );
};

export default Settings;
