import { Operators } from './types';
import {
  Column,
  DataItem,
  Formula,
  TotalFormattersTypes,
} from '../../../types/table';
import { formatTotal } from '../formatters';
import { ReactNode } from 'react';

export const operatorOptions = {
  [Operators.plus]: '+',
  [Operators.minus]: '-',
  [Operators.multiplication]: '×',
  [Operators.division]: '÷',
};

const getCalculatedValue = (
  data: DataItem[],
  type: TotalFormattersTypes,
  col: string,
) => {
  const values = data.reduce<ReactNode[]>((acc, el) => [...acc, el[col]], []);
  return formatTotal(values, type) || 0;
};

export const calculateData = (
  data: DataItem[],
  formulas: Formula[],
): DataItem => {
  return formulas.reduce((acc, formulaItem) => {
    const calculated = formulaItem.formula
      .map(el =>
        el?.type ? getCalculatedValue(data, el.type, el.key) : el.key,
      )
      .join('');
    const calculationResult = eval(calculated);
    return { ...acc, [formulaItem.id]: calculationResult };
  }, {});
};

export const getFormulaColumns = (formulas: Formula[]): Column[] =>
  formulas.map(el => ({ title: el.name, dataIndex: el.id, key: el.id }));
