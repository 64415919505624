import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, MenuProps, Select, SelectProps, Tag } from 'antd';
import {
  CheckOutlined,
  DownOutlined,
  VerticalAlignMiddleOutlined,
} from '@ant-design/icons';

import { PivotGroupConf, TotalFormattersTypes } from '../../../types/table';
import styles from '../TableWidget.module.less';

import { FormatBy } from './FormatBy';
import { Formatters } from './types';
import { formattersDropdownItems } from '../formatters';

type TagRender = SelectProps['tagRender'];

type ValuesSelectProps = {
  values: PivotGroupConf['values'];
  handleChangeValues: (name: string) => (value: string[]) => void;
  formatters: PivotGroupConf['formatters'];
  handleChangeFormatter: (
    selectedColumn: string,
    value: {
      rules: Formatters[];
      options: Record<string, any>;
    },
  ) => void;
  handleRemoveFormatter: (selectedColumn: string) => void;
  onValueTotalTypeChange: (row: string, type: TotalFormattersTypes) => void;
  valueTotalTypes: Record<string, TotalFormattersTypes>;
} & Pick<SelectProps, 'options'>;

export const ValuesSelect = (props: ValuesSelectProps) => {
  const {
    values,
    options,
    handleChangeValues,
    formatters,
    handleChangeFormatter,
    handleRemoveFormatter,
    valueTotalTypes,
    onValueTotalTypeChange,
  } = props;

  const intl = useIntl();
  const [selectedColumn, setSelectedColumn] =
    useState<keyof PivotGroupConf['formatters']>('');

  const valuesTitle = intl.formatMessage({
    id: 'widget.Values',
    defaultMessage: 'Значения',
  });

  const onCloseModal = () => setSelectedColumn('');
  const onOpenModal = (value: string) => setSelectedColumn(value);

  const formatterValue = useMemo(() => {
    if (selectedColumn === '') {
      return {
        rules: [],
        options: {},
      };
    }

    return formatters?.[selectedColumn];
  }, [formatters, selectedColumn]);

  const tagRender: TagRender = props => {
    const { label, closable, onClose, value } = props;

    const selectedRowType = valueTotalTypes[value] || TotalFormattersTypes.sum;
    const items = Object.keys(formattersDropdownItems).map(key => ({
      key,
      label:
        formattersDropdownItems[key as keyof typeof formattersDropdownItems],
      ...(selectedRowType === key && { icon: <CheckOutlined /> }),
    }));

    const handleMenuClick: MenuProps['onClick'] = e =>
      onValueTotalTypeChange(value, e.key as TotalFormattersTypes);

    const onPreventMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const onCLick = () => onOpenModal(value);

    const closeHandler = (e: any) => {
      handleRemoveFormatter(value);
      onClose(e);
    };

    return (
      <Tag
        className="ant-select-selection-item"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={closeHandler}
        style={{ fontSize: 16 }}
      >
        {label}
        <span onClick={onCLick} className={styles.groupIcon}>
          <VerticalAlignMiddleOutlined rotate={90} />
        </span>
        <span className={styles.groupIcon}>
          <Dropdown menu={{ items, onClick: handleMenuClick }}>
            <DownOutlined />
          </Dropdown>
        </span>
      </Tag>
    );
  };

  return (
    <React.Fragment>
      <h4>{valuesTitle}</h4>
      <Select
        mode="multiple"
        size="large"
        placeholder={valuesTitle}
        value={values}
        onChange={handleChangeValues('values')}
        className={styles.select}
        options={options}
        tagRender={tagRender}
      />
      <FormatBy
        open={Boolean(selectedColumn)}
        onClose={onCloseModal}
        value={formatterValue}
        selectedColumn={selectedColumn}
        onChange={handleChangeFormatter}
      />
    </React.Fragment>
  );
};
