import React from 'react';
import { formattersDropdownItems } from '../formatters';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, List, MenuProps } from 'antd';
import { TotalFormattersTypes } from '../../../types/table';
import { uuid } from '../../../../../utils';
import styles from './Calculation.module.less';
import { CalcItem, FormulaSet } from './types';

interface ListItemProps {
  item: CalcItem;
  allElements: CalcItem[];
  selectedElements: FormulaSet[];
  setAllElements: (value: CalcItem[]) => void;
  setSelectedElements: (value: FormulaSet[]) => void;
}

const ListItem = ({
  item,
  selectedElements,
  allElements,
  setSelectedElements,
  setAllElements,
}: ListItemProps) => {
  const items = Object.keys(formattersDropdownItems).map(key => ({
    key,
    label: formattersDropdownItems[key as keyof typeof formattersDropdownItems],
    ...(item.type === key && { icon: <CheckOutlined /> }),
  }));

  const handleMenuClick: MenuProps['onClick'] = e => {
    e.domEvent.stopPropagation();
    const changed = allElements.map(el =>
      el.key === item.key ? { ...el, type: e.key as TotalFormattersTypes } : el,
    );
    setAllElements(changed);
  };

  const handleItemSelect = () => {
    const isOperator = !selectedElements.at(-1)?.type;
    if (isOperator) {
      setSelectedElements([
        ...selectedElements,
        { ...item, label: item.label || item.key, id: uuid() },
      ]);
    }
  };

  const menu = (
    <Dropdown menu={{ items, onClick: handleMenuClick }}>
      <DownOutlined />
    </Dropdown>
  );

  return (
    <List.Item
      onClick={handleItemSelect}
      className={styles.listItem}
      actions={[menu]}
    >
      {item.label || item.key} ({formattersDropdownItems[item.type]})
    </List.Item>
  );
};

export default ListItem;
