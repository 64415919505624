import {
  Column,
  DataItem,
  Periods,
  PivotGroupConf,
  TotalFormattersTypes,
} from '../../types/table';
import _ from 'lodash';
import moment from 'moment/moment';
import {
  checkKey,
  createPivotColumn,
  isDate,
  MODIFIED_KEY_PREFIX,
  periodFormats,
  ROWS_WITHOUT_COLUMN,
  tableDateFormat,
  TOTAL_COLUMN_KEY,
} from './helpers';
import { formatRowTotals, formatTotal } from './formatters';
import { intl } from '../../../../utils/intl';

const getDateFormat = (date: string, period: Periods) =>
  moment(date, tableDateFormat).format(periodFormats[period]);

export const totalLabel = intl.formatMessage({
  id: 'Common.Total',
  defaultMessage: 'Итого',
});

type groupedByColumns = DataItem[] | Record<string, DataItem[]>;

const groupRows = (
  data: DataItem[],
  rows: string[],
  i: number = 0,
  valueTotalTypes: Record<string, TotalFormattersTypes>,
  parentKey: string = '',
) => {
  let result = data;
  const row = rows[i];
  const hasKey = checkKey(row, data);

  if (row && hasKey) {
    // Группировка rows по значениям в формате { значение: [rows] }
    const groupedByRows = _.groupBy(result, el => el[row]);

    // Приведение в формат rows с children внутри которого сгруппированные rows
    result = Object.keys(groupedByRows).map(key => {
      const children = groupRows(
        groupedByRows[key],
        rows,
        i + 1,
        valueTotalTypes,
        `${parentKey}-${key}`,
      );
      const id = groupedByRows[key].map(({ id }) => id).join('-');
      const formattedTotals = formatRowTotals(
        groupedByRows[key],
        valueTotalTypes,
      );

      return {
        id,
        ...formattedTotals,
        [ROWS_WITHOUT_COLUMN]: key,
        key: `${parentKey}-${key}-${i}-${id}`,
        ...(!!rows[i + 1] && { children }),
      };
    });
  }

  return result;
};

const groupByDatesRecursive = (
  columns: string[],
  data: DataItem[],
  groupBy: PivotGroupConf['groupBy'],
  periodIndex: number = 0,
  colIndex: number,
): groupedByColumns => {
  const col = columns[colIndex];
  const period = groupBy[col][periodIndex];
  if (period) {
    const grouped = _.groupBy(data, row => {
      const date = isDate(row[col])
        ? getDateFormat(row[col], period)
        : row[col];
      return period ? date : row[col];
    });
    return Object.keys(grouped).reduce(
      (acc, key) => ({
        ...acc,
        [key]: groupByColumnsRecursive(
          columns,
          grouped[key],
          colIndex,
          groupBy,
          periodIndex + 1,
        ),
      }),
      {},
    );
  }
  return groupByColumnsRecursive(columns, data, colIndex + 1, groupBy);
};

const groupByColumnsRecursive = (
  columns: string[],
  data: DataItem[],
  i: number = 0,
  groupBy: PivotGroupConf['groupBy'],
  periodIndex: number = 0,
): groupedByColumns => {
  const col = columns[i];
  const hasKey = checkKey(col, data);
  const period = groupBy?.[col]?.[periodIndex];
  if (period && hasKey) {
    return groupByDatesRecursive(columns, data, groupBy, periodIndex, i);
  } else if (hasKey) {
    const grouped = _.groupBy(data, row => row[col]);
    return Object.keys(grouped).reduce(
      (acc, key) => ({
        ...acc,
        [key]: groupByColumnsRecursive(columns, grouped[key], i + 1, groupBy),
      }),
      {},
    );
  }
  return data;
};

const calculateTotals = (
  data: DataItem[],
  valueKeys: string[],
  parentKey: string,
  valueTotalTypes: Record<string, TotalFormattersTypes>,
  isLastInstance: boolean,
  hasRowGrouping: boolean,
): DataItem => {
  return valueKeys.reduce((acc, key) => {
    const values = data.map(dataItem => dataItem?.[key]);
    const totalType =
      hasRowGrouping && valueTotalTypes[key] === TotalFormattersTypes.count
        ? TotalFormattersTypes.sum
        : valueTotalTypes[key];
    const children = data
      .map(dataItem => dataItem?.children)
      .filter(Boolean) as DataItem[][];
    const hasChildren = children.length;
    const columnsKey = `${MODIFIED_KEY_PREFIX}${parentKey}${key}`;
    const totalColumnKey = `${parentKey}${TOTAL_COLUMN_KEY}${key}`;
    return {
      ...acc,
      [ROWS_WITHOUT_COLUMN]: data[0]?.[ROWS_WITHOUT_COLUMN],
      key: data[0]?.key,
      [isLastInstance ? columnsKey : totalColumnKey]: formatTotal(
        values,
        totalType,
      ),
      ...(hasChildren && {
        children: calculateValues(
          children,
          valueKeys,
          parentKey,
          valueTotalTypes,
          isLastInstance,
          hasRowGrouping,
        ),
      }),
    };
  }, {});
};

function calculateValues(
  dataSet: DataItem[][],
  valueKeys: string[],
  parentKey: string,
  valueTotalTypes: Record<string, TotalFormattersTypes>,
  isLastInstance: boolean,
  hasRowGrouping: boolean,
): DataItem[] {
  const maxLength = Math.max(...dataSet.map(el => el.length), 0);
  if (hasRowGrouping) {
    const groupedByRow = _.groupBy(
      dataSet.flat(Infinity) as DataItem[],
      el => el?.[ROWS_WITHOUT_COLUMN],
    );
    return Object.keys(groupedByRow)
      .filter(key => key !== 'undefined')
      .map(key =>
        calculateTotals(
          groupedByRow[key],
          valueKeys,
          parentKey,
          valueTotalTypes,
          isLastInstance,
          hasRowGrouping,
        ),
      );
  }
  return [...Array(maxLength).keys()].reduce<DataItem[]>((acc, _, i) => {
    const dataItems = dataSet.map(el => el?.[i]);
    return [
      ...acc,
      calculateTotals(
        dataItems,
        valueKeys,
        parentKey,
        valueTotalTypes,
        isLastInstance,
        hasRowGrouping,
      ),
    ];
  }, []);
}

const mergeData = (
  dataSet: DataItem[][],
  hasRowGrouping: boolean,
): DataItem[] => {
  const maxLength = Math.max(...dataSet.map(el => el.length), 0);
  if (hasRowGrouping) {
    const groupedByRow = _.groupBy(
      dataSet.flat(Infinity) as DataItem[],
      el => el?.[ROWS_WITHOUT_COLUMN],
    );
    return Object.keys(groupedByRow)
      .filter(key => key !== 'undefined')
      .map(key =>
        groupedByRow[key].reduce((acc, el) => {
          const children = groupedByRow[key]
            .map(item => item.children)
            .filter(Boolean) as DataItem[][];
          return {
            ...acc,
            ...el,
            ...(children.length && {
              children: mergeData(children, hasRowGrouping),
            }),
          };
        }, {}),
      );
  }
  return [...Array(maxLength).keys()].reduce<DataItem[]>((acc, _, i) => {
    const mergeObj = dataSet.reduce(
      (dataAcc, el) => ({ ...dataAcc, ...el[i] }),
      {},
    );
    return [...acc, mergeObj];
  }, []);
};

const calculateData = (
  grouped: groupedByColumns = {},
  formulas: PivotGroupConf['formulas'],
  valueKeys: string[],
  rows: string[],
  valueTotalTypes: Record<string, TotalFormattersTypes>,
): DataItem[] => {
  let result: DataItem[] = [];
  const setGathered = (
    data: groupedByColumns,
    parentKey: string = '',
  ): DataItem[][] =>
    Object.entries(data).reduce<DataItem[][]>((acc, [key, value]) => {
      const keyPrefix = `${parentKey}${key}`;
      const isLastInstance = Array.isArray(value);
      const gathered = isLastInstance
        ? [groupRows(value, rows, 0, valueTotalTypes)]
        : setGathered(value, keyPrefix);
      const calculated = calculateValues(
        gathered,
        valueKeys,
        keyPrefix,
        valueTotalTypes,
        isLastInstance,
        !!rows.length,
      );
      result = mergeData([result, calculated], !!rows.length);
      return [...acc, ...gathered];
    }, []);
  if (Array.isArray(grouped)) {
    const groupedByRows = groupRows(grouped, rows, 0, valueTotalTypes);
    result = calculateValues(
      [groupedByRows],
      valueKeys,
      '',
      valueTotalTypes,
      true,
      !!rows.length,
    );
  } else {
    setGathered(grouped);
  }
  return result;
};

const getColumns = (
  data: groupedByColumns = [],
  values: string[],
  prefix: string = '',
  showColumnTotal: boolean,
): Column[] => {
  const isArray = Array.isArray(data);
  if (isArray) {
    return values.map(el =>
      createPivotColumn(`${MODIFIED_KEY_PREFIX}${prefix}${el}`, el),
    );
  }
  return Object.keys(data).reduce<Column[]>((acc, key) => {
    const column = `${prefix}${key}`;
    const children = getColumns(data[key], values, column, showColumnTotal);
    const totals =
      !Array.isArray(data[key]) && showColumnTotal
        ? values.map(el =>
            createPivotColumn(
              `${column}${TOTAL_COLUMN_KEY}${el}`,
              `${totalLabel} ${el}`,
            ),
          )
        : [];
    return [...acc, createPivotColumn(column, key, children), ...totals];
  }, []);
};

const group = (
  columns: string[],
  rows: string[],
  values: string[],
  data: DataItem[],
  groupBy: PivotGroupConf['groupBy'],
  formulas: PivotGroupConf['formulas'],
  valueTotalTypes: Record<string, TotalFormattersTypes>,
  showColumnTotal: boolean = false,
) => {
  const result = groupByColumnsRecursive(columns, data, 0, groupBy);

  const groupedColumns = getColumns(result, values, '', showColumnTotal);

  return {
    data: calculateData(result, formulas, values, rows, valueTotalTypes),
    columns: groupedColumns,
  };
};

export const pivotDataFormatter = (
  data: DataItem[],
  config: PivotGroupConf,
) => {
  const columnForRow: Column = {
    title: '',
    dataIndex: ROWS_WITHOUT_COLUMN,
    key: ROWS_WITHOUT_COLUMN,
  };
  const {
    columns,
    values,
    rows,
    groupBy,
    valueTotalTypes,
    formulas,
    showColumnTotal,
    showRowTotal,
  } = config;
  const grouped = group(
    columns,
    rows,
    values,
    data,
    groupBy,
    formulas,
    valueTotalTypes,
    showColumnTotal,
  );

  const rowColumn = rows.length ? [columnForRow] : [];

  const total =
    rows.length && showRowTotal
      ? [
          {
            ...formatRowTotals(grouped.data, valueTotalTypes, true),
            [ROWS_WITHOUT_COLUMN]: totalLabel,
          },
        ]
      : [];
  return {
    data: [...grouped.data, ...total],
    columns: [...rowColumn, ...grouped.columns],
  };
};
