import { ReactNode } from 'react';
import { Column, DataItem, TotalFormattersTypes } from '../../types/table';
import { intl } from '../../../../utils/intl';

const sum = (values: ReactNode[]) =>
  values.reduce(
    (acc: number, el) => (Number.isFinite(el) ? acc + (el as number) : acc),
    0,
  );

const min = (values: ReactNode[]) => {
  const filtered = values.filter(el => Number.isFinite(el)) as number[];
  return filtered.length ? Math.min(...filtered) : 0;
};

const max = (values: ReactNode[]) => {
  const filtered = values.filter(el => Number.isFinite(el)) as number[];
  return filtered.length ? Math.max(...filtered) : 0;
};

const average = (values: ReactNode[]) => {
  const filtered = values.filter(el => Number.isFinite(el));
  return filtered.length ? sum(filtered) / filtered.length : 0;
};

const count = (values: ReactNode[]) => {
  const filtered = values.filter(el => el ?? el);
  return filtered.length;
};

export const totalFormatters = {
  [TotalFormattersTypes.sum]: sum,
  [TotalFormattersTypes.min]: min,
  [TotalFormattersTypes.max]: max,
  [TotalFormattersTypes.average]: average,
  [TotalFormattersTypes.count]: count,
};

export const formatTotal = (
  values: ReactNode[],
  type: TotalFormattersTypes = TotalFormattersTypes.sum,
) => {
  const hasNumber = values.some(el => Number.isFinite(el));
  const isCountType = type === TotalFormattersTypes.count;
  const value = isCountType ? count(values) : null;
  return hasNumber ? totalFormatters[type](values) : value;
};

export const formatRowTotals = (
  data: DataItem[],
  valueTotalTypes: Record<string, TotalFormattersTypes>,
  isTotal: boolean = false,
) => {
  // Группировка ключ: [значения] для форматирования
  const grouped = data.reduce<Record<Column['dataIndex'], any[]>>((acc, el) => {
    Object.keys(el).forEach(key =>
      Array.isArray(acc[key]) ? acc[key].push(el[key]) : (acc[key] = [el[key]]),
    );
    return acc;
  }, {});
  // Форматирование значений и приведение в исходный вид row { ключ: форматированное значение }
  return Object.keys(grouped).reduce<DataItem>(
    (acc, key) => ({
      ...acc,
      [key]: formatTotal(
        grouped[key],
        isTotal ? TotalFormattersTypes.sum : valueTotalTypes[key],
      ),
    }),
    {},
  );
};

const minLabel = intl.formatMessage({
  id: 'Format.Min',
  defaultMessage: 'Мин.',
});
const maxLabel = intl.formatMessage({
  id: 'Format.Max',
  defaultMessage: 'Макс.',
});
const averageLabel = intl.formatMessage({
  id: 'Format.Average',
  defaultMessage: 'Среднее',
});
const countLabel = intl.formatMessage({
  id: 'Format.Count',
  defaultMessage: 'Количество',
});
const sumLabel = intl.formatMessage({
  id: 'Format.Sum',
  defaultMessage: 'Сумма',
});

export const formattersDropdownItems: Record<TotalFormattersTypes, string> = {
  [TotalFormattersTypes.sum]: sumLabel,
  [TotalFormattersTypes.max]: maxLabel,
  [TotalFormattersTypes.min]: minLabel,
  [TotalFormattersTypes.average]: averageLabel,
  [TotalFormattersTypes.count]: countLabel,
};
