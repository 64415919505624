import { TotalFormattersTypes } from '../../../types/table';
export const enum Operators {
  plus = '+',
  minus = '-',
  multiplication = '*',
  division = '/',
}

export interface CalcItem {
  key: string;
  label?: string;
  type: TotalFormattersTypes;
}

export interface FormulaSet {
  key: string | Operators;
  type?: TotalFormattersTypes;
  label: string;
  id: string;
}
