import React, { forwardRef, useMemo } from 'react';
import { Input, Modal, Select, Form } from 'antd';

import { FormattedMessage } from 'react-intl';
import styles from '../TableWidget.module.less';
import { Formatters } from './types';
import { DEFAULT_ROUND, formattersOptions } from './utils';
import { intl } from '../../../../../utils/intl';

type FormatByProps = {
  selectedColumn: string;
  open: boolean;
  onClose: () => void;
  value: {
    rules: Formatters[];
    options: Record<string, any>;
  };
  onChange: (
    selectedColumn: string,
    value: {
      rules: Formatters[];
      options: Record<string, any>;
    },
  ) => void;
};

const saveTitle = intl.formatMessage({
  id: 'Common.save',
  defaultMessage: 'Сохранить',
});

const formatByTitle = intl.formatMessage({
  id: 'Common.FormatBy',
  defaultMessage: 'Форматировать по',
});

const CustomInput = forwardRef((props, ref: any) => {
  return (
    <div className={styles.input}>
      <label htmlFor="format-by-round">
        <FormattedMessage
          id="TableWidgetFormatter.RoundDigits"
          defaultMessage="Цифр после запятой:"
        />
      </label>
      <Input id="format-by-round" type="number" max={6} {...props} ref={ref} />
    </div>
  );
});

export const FormatBy = ({
  open,
  selectedColumn,
  onClose,
  value,
  onChange,
}: FormatByProps) => {
  const [form] = Form.useForm<{ rules: Formatters[]; round: number }>();
  const watchedRules = Form.useWatch<Formatters[]>('rules', form);

  const rules = useMemo(() => {
    return value?.rules || [];
  }, [value?.rules]);

  const round = useMemo(() => {
    return value?.options?.round || DEFAULT_ROUND;
  }, [value?.options?.round]);

  const onCancel = () => {
    onClose();
  };

  const submit = () => {
    const formValues = form.getFieldsValue();

    const newFormatter: {
      rules: Formatters[];
      options: Record<string, any>;
    } = {
      rules: formValues?.rules,
      options: {},
    };

    if (formValues?.rules.includes(Formatters.ROUND)) {
      newFormatter.options = {
        round: formValues.round,
      };
    }

    onChange(selectedColumn, newFormatter);
    onClose();
  };

  const hasRoundFormatter = useMemo(() => {
    return watchedRules?.some(i => i === Formatters.ROUND);
  }, [watchedRules]);

  return (
    <Modal
      title={selectedColumn}
      okText={saveTitle}
      onOk={submit}
      onCancel={onCancel}
      centered
      open={open}
      destroyOnClose
    >
      <Form form={form}>
        <h4>{formatByTitle}:</h4>
        <Form.Item
          name="rules"
          className={styles['margin-lees']}
          initialValue={rules}
        >
          <Select
            mode="multiple"
            size="large"
            options={formattersOptions}
            className={styles.select}
          />
        </Form.Item>

        {hasRoundFormatter && (
          <Form.Item
            name="round"
            className={styles['margin-lees']}
            initialValue={round}
          >
            <CustomInput />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
