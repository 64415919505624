export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  USER = 'USER',
  AUDITOR = 'AUDITOR',
}

export interface Profile {
  birthDay: string;
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  middleName: string;
  phone: string;
  groupNames: string[];
}

export type Department = {
  displayName: null | string;
  id: string;
  name: string;
};

export interface User<T = Profile> {
  roleName: string;
  id: string;
  username: string;
  enabled: boolean;
  profile: T;
  authorities: Permissions[];
  lastEntrance: string | null;
  department: Department;
}

export enum Permissions {
  DASHBOARD_EDIT = 'DASHBOARD_EDIT',
  GROUP_EDIT = 'GROUP_EDIT',
  USER_EDIT = 'USER_EDIT',
  PRIVILEGE_EDIT = 'PRIVILEGE_EDIT',
  DATA_EDIT = 'DATA_EDIT',
  DASHBOARD_READ = 'DASHBOARD_READ',
  GROUP_READ = 'GROUP_READ',
  USER_READ = 'USER_READ',
  DATA_ETL = 'DATA_ETL',
  AUDIT_READ = 'AUDIT_READ',
  DATA_LOAD = 'DATA_LOAD',
}

export interface Role {
  description: string | null;
  id: string;
  name: string;
  systemName: UserRoleEnum;
}

export enum Sources {
  HOME = 'HOME',
  KC = 'KC',
  INTEGRATION = 'INTEGRATION',
}

export enum FieldNames {
  username = 'username',
  enabled = 'enabled',
  externalId = 'externalId',
  birthDay = 'birthDay',
  firstName = 'firstName',
  lastName = 'lastName',
  middleName = 'middleName',
  email = 'email',
  phone = 'phone',
  roles = 'roles',
}

export interface FieldConfig {
  name: string;
  source: Sources;
  fieldNameInKC: string | null;
  required: boolean;
}

export type UserSettings = Partial<Record<FieldNames, FieldConfig>>;
