import React, { useState } from 'react';
import { Checkbox, Modal, Select, SelectProps, Tag } from 'antd';
import { useIntl } from 'react-intl';
import {
  DataItem,
  Periods,
  PivotGroupConf,
  TotalFormattersTypes,
} from '../../types/table';
import styles from './TableWidget.module.less';
import { VerticalAlignMiddleOutlined } from '@ant-design/icons';
import GroupBy from './GroupBy';
import RowsSelect from './RowsSelect';

import { Formatters, ValuesSelect } from './ValuesSelect';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface TableSettingsModalProps {
  data: DataItem[];
  selected: PivotGroupConf;
  setSelected: (selected: PivotGroupConf) => void;
  isOpen: boolean;
  onClose: () => void;
}

type TagRender = SelectProps['tagRender'];

const TableSettingsModal = ({
  data,
  setSelected,
  selected,
  isOpen,
  onClose,
}: TableSettingsModalProps) => {
  const intl = useIntl();
  const [state, setState] = useState<PivotGroupConf>(selected);
  const [col, setCol] = useState<keyof (typeof selected)['groupBy']>('');
  const { rows, columns, values } = state;
  const fields = { rows, columns, values };

  const options = Object.keys(data[0]).map(key => ({ label: key, value: key }));

  const saveTitle = intl.formatMessage({
    id: 'Common.save',
    defaultMessage: 'Сохранить',
  });

  const fieldsTitle = intl.formatMessage({
    id: 'widget.Fields',
    defaultMessage: 'Поля',
  });

  const columnsTitle = intl.formatMessage({
    id: 'widget.Columns',
    defaultMessage: 'Столбцы',
  });

  const showTotalTitle = intl.formatMessage({
    id: 'table.ShowTotal',
    defaultMessage: 'Показывать итоги',
  });

  const unSelectFromOthers = (
    field: keyof typeof fields,
    options: string[],
  ) => {
    const { [field]: deletedKey, ...other } = fields;
    const otherFields = { ...other } as Record<string, string[]>;
    return Object.keys(otherFields).reduce(
      (acc, key) => ({
        ...acc,
        [key]: otherFields[key].filter((el: string) => !options.includes(el)),
      }),
      state,
    );
  };

  const handleChange = (name: string) => (value: string[]) => {
    const unselected = unSelectFromOthers(name as keyof typeof fields, value);
    const { rows, values, columns, ...other } = state;
    setState({ ...unselected, ...other, [name]: value });
  };

  const onCancel = () => {
    setState(selected);
    onClose();
  };

  const submit = () => {
    onClose();
    setSelected(state);
  };

  const onCloseGroupModal = () => setCol('');
  const onOpenGroupModal = (value: string) => setCol(value);

  const columnsTagRender: TagRender = props => {
    const { label, closable, onClose, value } = props;
    const onPreventMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const onCLick = () => onOpenGroupModal(value);
    return (
      <Tag
        className="ant-select-selection-item"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ fontSize: 16 }}
      >
        {label}
        <span onClick={onCLick} className={styles.groupIcon}>
          <VerticalAlignMiddleOutlined rotate={90} />
        </span>
      </Tag>
    );
  };

  const groupByValue = state?.groupBy?.[col] || [];

  const handleChangeGroup = (value: Periods[]) =>
    setState({
      ...state,
      groupBy: { ...state?.groupBy, [col]: value },
    });

  const handleChangeFormatter = (
    selectedColumn: string,
    value: {
      rules: Formatters[];
      options: Record<string, any>;
    },
  ) =>
    setState({
      ...state,
      formatters: Object.assign(state?.formatters || {}, {
        [selectedColumn]: value,
      }),
    });

  const handleValueTotalTypeChange = (
    value: string,
    type: TotalFormattersTypes,
  ) =>
    setState({
      ...state,
      valueTotalTypes: { ...state.valueTotalTypes, [value]: type },
    });

  const handleRemoveFormatter = (selectedColumn: string) => {
    const copyFormatters = JSON.parse(JSON.stringify(state.formatters));

    delete copyFormatters[selectedColumn];

    setState({
      ...state,
      formatters: copyFormatters,
    });
  };

  const handleChangeTotalVisibility =
    (name: keyof PivotGroupConf) => (e: CheckboxChangeEvent) =>
      setState({
        ...state,
        [name]: e.target.checked,
      });

  return (
    <React.Fragment>
      <GroupBy
        value={groupByValue}
        onChange={handleChangeGroup}
        col={col}
        open={!!col}
        onClose={onCloseGroupModal}
      />
      <Modal
        title={fieldsTitle}
        okText={saveTitle}
        onCancel={onCancel}
        onOk={submit}
        centered
        open={isOpen}
      >
        <div className={styles.tableSettingsContainer}>
          <div>
            <h4>{columnsTitle}</h4>
            <Select
              mode="multiple"
              size="large"
              value={state.columns}
              placeholder={columnsTitle}
              tagRender={columnsTagRender}
              onChange={handleChange('columns')}
              className={styles.select}
              options={options}
            />
            <Checkbox
              checked={state.showColumnTotal}
              className={styles.totalCheckbox}
              onChange={handleChangeTotalVisibility('showColumnTotal')}
            >
              {showTotalTitle}
            </Checkbox>
          </div>
          <div>
            <RowsSelect
              options={options}
              rows={state.rows}
              onChange={handleChange('rows')}
            />
            <Checkbox
              checked={state.showRowTotal}
              className={styles.totalCheckbox}
              onChange={handleChangeTotalVisibility('showRowTotal')}
            >
              {showTotalTitle}
            </Checkbox>
          </div>
          <div>
            <ValuesSelect
              values={state.values}
              onValueTotalTypeChange={handleValueTotalTypeChange}
              valueTotalTypes={state.valueTotalTypes}
              handleChangeValues={handleChange}
              options={options}
              formatters={state.formatters}
              handleChangeFormatter={handleChangeFormatter}
              handleRemoveFormatter={handleRemoveFormatter}
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TableSettingsModal;
